import "./widgetLg.css";
import moment from 'moment'

export default function WidgetLg({latestbookings}) {
  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest Bookings</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Booking Id</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Time</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        {

latestbookings.map((booking)=>(
  <tr className="widgetLgTr">
  <td className="widgetLgUser">
    <span className="widgetLgName">{booking._id}</span>
  </td>
  <td className="widgetLgDate">{moment(booking.timeslot.date).format("MMMM Do YYYY")}</td>
  <td className="widgetLgDate"> {booking.timeslot.startTime}-{booking.timeslot.endTime}</td>
  <td className="widgetLgAmount">R{booking.amount}</td>
  <td className="widgetLgStatus">
 
   {booking.status}
  </td>
</tr>
))
        }
       
      </table>
    </div>
  );
}
