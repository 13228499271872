import { useState,useEffect } from "react";
import './Bookings.css';
import Layout from '../../components/Layout/Layout'
import { axiosPrivate } from "../../api/axios";
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const Bookings = () => {


  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken

  const userId= jwt_decode(token)

  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);





  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(`/api/bookings?userId=${userId.UserInfo.userId}`,{
          headers: {
            'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
          }
        });
        setData(response.data.bookings);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    // Clean up function
    return () => {
      setData(null);
      setLoading(true);
      setError(null);
    };
  }, []);
  
 
  


    if (loading) {
      return <div className="loading-container"><Loader/></div>;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }

  return (
    <Layout>
<div className="bookings-page">
      <h2>My Bookings</h2>
   {
    data.length>0 ?(
      <div className="upcoming-bookings">
       
      {data.map((booking) => (
    <div key={booking._id} className="booking">
      <div className="booking-info">
      <h3>Treatment Details</h3>
     {booking.service.map((data)=>(
     
       <p className="booking-date">{data.name}</p>
     ))}
     <h3>Date and Time</h3>
        <p className="booking-date">{moment(booking.timeslot.date).format('dddd, MMMM D, YYYY')}</p>
        <p className="booking-time">{booking.timeslot.startTime}-{booking.timeslot.endTime}</p>
      </div>
      <div className="booking-status">
        <p>{booking.status.toUpperCase()}</p>
      </div>
    </div>
  ))}
    </div>
    ):(
<span>No Bookings</span>
    )
   }
      
    </div>
    </Layout>
    
  );
};

export default Bookings;
