import { useState, useRef } from "react";
import './ForgotPassword.css';
import { Link } from 'react-router-dom'
import { axios } from '../../api/axios'
import Loader from "../../components/Loader/Loader";


const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('')

  const userRef = useRef();
  const errRef = useRef();


  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post("/api/auth/emailsendforpasswordreset",
        { email },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      setSuccess(true)
      setEmail('');
      setLoading(false)

    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Email not found');
      }
      else {
        setErrMsg('Email failed to be sent');
      }
      errRef.current.focus();
      setLoading(false)

    }
  }


  return (
    <div className="forgot-password-container">


      {
        success ? (<div>Submitted successfully.Please check your email.</div>) : (
          <div className="form-container">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  ref={userRef}
                  type='text'
                  id="email"
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button>{loading? <Loader/>:"Submit"}</button>
              <div className="links">
                <Link to="/login" style={{ textDecoration: 'none' }}>
                  <span>Log In</span>
                </Link>
              </div>

            </form>
          </div>
        )
      }

    </div>
  )
};

export default ForgotPassword;