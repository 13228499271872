import React from 'react';
import BottomNavigation from '../Navigation/BottomNavigation';

function Layout({ children }) {
  return (
    <div>
      {children}
      <BottomNavigation />
    </div>
  );
}

export default Layout;
