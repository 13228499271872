import { useState, useEffect, useRef } from "react";
import "./Profile.css";
import { axiosPrivate } from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import useLogout from "../../hooks/useLogout";
import { Logout } from "@mui/icons-material";
import Loader from "../../components/Loader/Loader";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const { id } = useParams();

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;

  const [loading, setLoading] = useState(false);
  const [loadingForUpdate,setLoadingForUpdate]=useState(false)

  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();

  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const confirm = window.confirm("Are you sure you want to log out");
    if (confirm) {
      await logout();
    }
  };

  const updateProfile = async (event) => {
    event.preventDefault();
    
    try {
    
      const response = await axiosPrivate.put(
        `/api/users/${id}`,
        { name, surname ,phoneNumber},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
     
      setLoadingForUpdate(false);
      toast.success('You have updated your details successfully!');
    } catch (err) {
      
      setLoadingForUpdate(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
   
      try {
        setLoading(true)
        const response = await axiosPrivate.get(`/api/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response.data.name);
        setSurname(response.data.surname);
        setEmail(response.data.email);
        setPhoneNumber(response.data.phoneNumber)
        setLoading(false)
      } catch (err) {
       
        setLoading(false)
      }
    };
    fetchUserData();
  }, []);



  return (
    <Layout>
     {
    <div className="profile-container">
    <form className="profile-form">
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <h2>My Account</h2>

      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="surname">Surname</label>
        <input
          type="text"
          id="surname"
          name="surname"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phonenumber">Phone Number</label>
        <input
          type="text"
          id="phonenumber"
          name="phonenumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div>


      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          readOnly
        />
      </div>

      <button className="profile-button" onClick={ updateProfile }>{loadingForUpdate?<Loader/>:"Save"}</button>
      <div className="signout">
        <Logout onClick={handleLogout} />
        Log Out
      </div>
    </form>
  </div>
     }
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Layout>
  );
};

export default Profile;
