import React from 'react';
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import './Layout.css'

function AdminLayout({ children }) {
  return (
    <>
    <Topbar />
      <div className='container'>
      <Sidebar/>
      {children}
      </div>
      
    </>
  );
}

export default AdminLayout;
