import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from '../hooks/useRefreshToken'
import { useSelector } from 'react-redux'
import Loader from './Loader/Loader'


const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    
    const accessToken = useSelector((state) => state.auth.accessToken)
   
   

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        !accessToken ? verifyRefreshToken() : setIsLoading(false)
    }, [])



    return (

        <>{ isLoading 
            ?  <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}><Loader /></div>
            : <Outlet />}
        </>
    )

}

export default PersistLogin