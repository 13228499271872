import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Calendar from './components/Calendar/Calendar';
import ConfirmDetails from './pages/ConfirmDetailsPage/ConfirmDetailsPage';
import HomePage from './pages/Homepage/HomePage';
import TreatmentDetailsPage from './pages/TreatmentDetailsPage/TreatmentDetailsPage';
import Bookings from './pages/Bookings/Bookings';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/Register';
import LogIn from './pages/LogIn/LogIn';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"
import Unauthorized from './pages/Unauthorized/Unauthorized';
import PersistLogin from './components/PersistLogIn';
import Activate from './pages/Activate/Activate';
import PasswordReset from './pages/PasswordReset/PasswordReset'
import Treatment from './admin/src/pages/treatment/Treatment';
import Home from './admin/src/pages/home/Home';
import TreatmentsList from './admin/src/pages/treatmentList/TreatmentsList';
import UserList from './admin/src/pages/userList/UserList';
import BookingList from './admin/src/pages/bookingList/BookingList';

import NewTreatment from './admin/src/pages/newTreatment/newTreatment';
import Success from './pages/Success/Success';
import Cart from './pages/Cart/Cart';
import  Welcome from './pages/Welcome/Welcome';
import ConsentForm from './pages/Consent/ConsentForm';
import EditConsentForm from './pages/Consent/EditConsentForm';
import { Consents } from './admin/src/pages/consents/Consents';
import ViewConsent from './admin/src/pages/viewConsent/ViewConsent';
import { Booking } from './admin/src/pages/viewBooking/Booking';
function App() {

  return (
   <>
   
    <Routes>
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<LogIn />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
        <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/payment/success' element={<Success/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/welcome' element={<Welcome/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/' element={<HomePage />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/treatments/:id' element={<TreatmentDetailsPage />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/calendar' element={<Calendar />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/confirm' element={<ConfirmDetails />} />
          </Route>
          
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/bookings' element={<Bookings />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/profile/:id' element={<Profile />} />
          </Route>
          
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/cart' element={<Cart/>} />
          </Route>
        
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/consentform' element={<ConsentForm/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['user']} />}>
            <Route path='/consentform/:id' element={<EditConsentForm/>} />
          </Route>


          {/**admin routes */}
        
         <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
              <Route path="/admin/dashboard" element={<Home />} />
         </Route>
         <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
              <Route path="/admin/dashboard/treatments" element={<TreatmentsList />} />
         </Route>
         <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
              <Route path="/admin/dashboard/users" element={<UserList />} />
         </Route>
         <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
              <Route path="/admin/dashboard/bookings" element={<BookingList />} />
         </Route>
        
         <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/profile/:id' element={<Profile />} />
          </Route>
        
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/newtreatment' element={<NewTreatment />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/treatments/:id' element={<Treatment/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/users/:id/consents' element={<Consents/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/users/:id/consents/:consentid' element={<ViewConsent/>} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path='/admin/dashboard/bookings/:id'  element={<Booking/>} />
          </Route>
        </Route>
      </Routes>
   </>
     
   
  );
}

export default App;
