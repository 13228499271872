import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {useSelector} from 'react-redux'

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();

    const accessToken =useSelector((state)=>state.auth.accessToken)
    const user=useSelector((state)=>state.auth)

  
    if (!accessToken) {
      // If the user is not authenticated, redirect them to the login page
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    try {
      const decoded = jwt_decode(accessToken);

      // Check if the token has expired
      if (decoded.exp * 1000 < Date.now()) {
        // If the token has expired, redirect the user to the login page
        return <Navigate to="/login" state={{ from: location }} replace />;
      }
  
      // Check if the user has the required role
      if (
        allowedRoles.includes(decoded.UserInfo.roles)
      ) {
        return <Outlet />;
      }else{
        return <Navigate to="/login" state={{ from: location }} replace />;
      }
  
    
    } catch (error) {
      // If there is an error decoding the token, redirect the user to the login page
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  };
  

export default RequireAuth;