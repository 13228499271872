import "./featuredInfo.css";


export default function FeaturedInfo({totalBookings,totalCustomers,revenue}) {
  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Revenue</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">R{revenue}</span>
        </div>
       
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Bookings</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalBookings}</span>
          
        </div>
       
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Customers</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalCustomers}</span>
          
        </div>
       
      </div>
    </div>
  );
}
