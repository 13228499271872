import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./HomePage.css";
import Cards from "../../components/Cards/Cards";
import { axios } from "../../api/axios";
import logo from "../../images/logo.png"
import Loader from "../../components/Loader/Loader";

const HomePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");
  const [originalData, setOriginalData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/treatments`);
        setData(response.data);
        setOriginalData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    setQuery(e.target.value);
    if (e.target.value === "") {
      setData(originalData);
    } else {
      const filteredData = data.filter((item) => {
        const itemName = item.name.toLowerCase();
        const itemDescription = item.description.toLowerCase();
        const searchTerm = query.toLowerCase();
        return (
          itemName.includes(searchTerm) || itemDescription.includes(searchTerm)
        );
      });

      setData(filteredData);
    }
  };

  if (loading) {
    return <div className="loading-container"><Loader/></div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Layout>
      <div className="homepage-container">
       <div className="logo-container"><img src={logo}/></div>
        <div className="search-bar">
          <input
            type="text"
            value={query}
            onChange={handleSearch}
            placeholder="Type to search treatments"
          />
        </div>

        {data.length > 0 ? <Cards data={data} /> : <span>No treatments</span>}
      </div>
    </Layout>
  );
};

export default HomePage;
