import React ,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux';
import { axiosPrivate } from '../../../../api/axios';
import { useParams ,useNavigate} from 'react-router-dom';
import './Consents.css'
import BookIcon from '@mui/icons-material/Book';
import Chip from '@mui/material/Chip';

export const Consents = () => {
    const auth = useSelector((state) => state.auth);
    const token = auth.accessToken;
    
    const [data,setData]=useState([])
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState("")

    const {id}=useParams()
  //Fetch Consents
  useEffect(() => {
   

    const fetchConsents = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/consents?id=${id}`,
          {
            headers: {
              'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            },
          }
        );
        setData(response.data);
      
       
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchConsents();
  }, [id])


  const navigate=useNavigate()
  const handleClick=(consentid)=>{
navigate(`/admin/dashboard/users/${id}/consents/${consentid}`)
  }
 
  if(data.length==0){
return(
    <span>No consent has been submmited yet</span>
)
  }

  return (
    <div className='main-consent-container'>
 <div className='consents-container'>
        
 <h1 className='consents-heading'>Consent Forms For {data[0]?.user.name} {" "} {data[0]?.user.surname}</h1>
        {data && data.map((consent,index)=>(
         <div key={consent._id} className='consents-cards'>
            <div className='consent-card-inner-container'>
            <BookIcon sx={{fontSize:"2rem" }}/>
            Consent{" "}{index+1}
           </div>
           <Chip label="View" variant="outlined" onClick={()=>handleClick(consent._id)} />
         </div>
       ))}



    </div>
    </div>
   
  )
}
