import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { axios } from '../../api/axios';
import "./Activate.css"

const Activate = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
 

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const errRef = useRef();

  useEffect(() => {
    const activateAccount = async () => {
      setLoading(true)
      try {
        const response = await axios.post('/api/auth/activate', { token });
        setMessage(response.data.message);
        setLoading(false)
      } catch (err) {
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 404) {
          setErrMsg('Activation token not found');
        } else if (err.response?.status === 400) {
          setErrMsg('Activation link has expired, please request a new one by logging in');
        }
        else {
          setErrMsg('Failed to activate');
        }
        errRef.current.focus();
        setLoading(false)
      }
    };

    activateAccount();
  }, [token]);

  return (
    <div className='activate-container'>
      {loading ? <div>Loading...</div> : ""}
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      {message && <p>{message}</p>}
      <Link to='/login'>
        <button >Log In</button>
      </Link>

    </div>
  );
};

export default Activate;
