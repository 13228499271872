import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Cart.css";
import Layout from "../../components/Layout/Layout";
import { removeItemFromCart } from "../../redux/slices/cart";

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const totalItems = useSelector((state) => state.cart.totalQuantity);

  const dispatch=useDispatch()
  const navigate = useNavigate();


  const handleClick=(id)=>{
dispatch(removeItemFromCart(id))
  }


  return (
    <Layout>
      <div className="cart">
        <h1>Your Cart </h1>
        {cartItems.length > 0 ? (
          <>
            <h2 className="cart-price-items">
              <span>Total Price: R{totalPrice}</span>
              <span>Total Items: {totalItems}</span>
            </h2>
            <hr className="cart-line" />
            <div className="cart-info">
              {cartItems.map((item) => (
                <div className="cart-card">
                  <img src={item.image} />
                  <span>{item.name}</span>
                  <span>R{item.price}</span>
                  <ClearIcon style={{ color: "red" }} onClick={()=>handleClick(item._id)}/>
                </div>
              ))}
              <hr className="cart-line" />
            </div>

            <button
              className="cart-button"
              onClick={() => navigate("/calendar")}
            >
              Continue
            </button>
          </>
        ) : (
          <h3 className="cart-empty">Your cart is empty</h3>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
