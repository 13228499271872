import "./newTreatment.css";
import React, { useState,useRef } from 'react';
import AdminLayout from "../../components/Layout/Layout";
import {axiosPrivate} from '../../../../api/axios'
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../../components/Loader/Loader";

const  NewTreatment=()=> {

  const [treatment, setTreatment] = useState({
    name: '',
    duration: '',
    image: null,
    price: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('');
  const errRef = useRef();

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken
  const decoded = jwtDecode(auth.accessToken);
  const therapist=decoded.UserInfo.userId

  
  // function to validate form fields
  const validateFields = () => {
    const validationErrors = {};
    if (!treatment.name.trim()) {
      validationErrors.name = 'Please enter a treatment name';
    }
    if (isNaN(parseInt(treatment.duration))) {
      validationErrors.duration = 'Please enter a valid duration (in minutes)';
    }
    if (isNaN(parseFloat(treatment.price))) {
      validationErrors.price = 'Please enter a valid price (in Rands)';
    }
    if (!treatment.image) {
      validationErrors.image = 'Please select an image';
    } else if (treatment.image.size > 1048576) { // 1MB
      validationErrors.image = 'File size too large. Maximum size is 1MB.';
    } else {
      const allowedExtensions = ['.jpg', '.jpeg', '.png'];
      const extension = treatment.image.name.split('.').pop();
      if (!allowedExtensions.includes(`.${extension}`)) {
        validationErrors.image = 'Invalid file type. Allowed types are JPG, JPEG, PNG.';
      }
    }
    if (!treatment.description.trim()) {
      validationErrors.description = 'Please enter a treatment description';
    }
    return validationErrors;
  };

  
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'image') {
      const file = files[0];
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const maxSize = 1 * 1024 * 1024; // 1MB
      if (file && allowedExtensions.includes(file.name.split('.').pop()) && file.size <= maxSize) {
        setTreatment({ ...treatment, image: file });
      } else {
        setErrors({ ...errors, image: 'Please select a valid image file (jpg, jpeg, png) within 1MB' });
      }
    } else {
      setTreatment({ ...treatment, [name]: value });
    }
    setErrors({});
  };
  
 
   // function to handle form submission
   const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateFields();
  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
  } else {
    setLoading(true);

    const formData = new FormData();
    formData.append('name', treatment.name);
    formData.append('duration', treatment.duration);
    formData.append('price', treatment.price);
    formData.append('description', treatment.description);
    formData.append('image', treatment.image);
    formData.append('therapist',therapist)
    axiosPrivate.post('/api/treatments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log('Treatment saved to database:', response.data);
        setLoading(false);
        setTreatment({
          name: '',
          duration: '',
          image: null,
          price: '',
          description: '',
        });
        toast.success('Treatment added!');
      })
      .catch((error) => {
        setErrMsg("Failed to create treatment");
        console.error('Error saving treatment to database:', error);
        setLoading(false);
       
      });
    }
  };





  return (
    <AdminLayout>
<form className="newtreatment-form" onSubmit={handleSubmit}>
<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        {loading ? <div>Loading...</div> : ""}
      <label htmlFor="treatmentName">Treatment Name:</label>
      <input
        type="text"
        id="treatmentName"
        name='name'
        value={treatment.name}
        onChange={handleChange}

      />
 {errors.name && <span className="error">{errors.name}</span>}
      <label htmlFor="duration">Duration:</label>
      <input
        type="number"
        id="duration"
        name='duration'
        value={treatment.duration}
        min="0"
        onChange={handleChange}
      />
{errors.duration && <span className="error">{errors.duration}</span>}

<label htmlFor="image" className="custom-file-upload">
  Upload Image
</label>
<input
  type="file"
  id="image"
  accept="image/*"
  name='image'
  onChange={handleChange}
/>
{errors.image && <span className="error">{errors.image}</span>}
{treatment.image && (
          <img
            alt="Selected Image"
            src={URL.createObjectURL(treatment.image)}
            className="preview-image"
          />
        )}

      <label htmlFor="price">Price:</label>
      <input
        type="number"
        id="price"
        value={treatment.price}
        name='price'
        min="0"
        onChange={handleChange}
      />
{errors.price && <span className="error">{errors.price}</span>}
      <label htmlFor="description">Description:</label>
      <textarea
        id="description"
        value={treatment.description}
        name='description'
        onChange={handleChange}
      />
{errors.description && <span className="error">{errors.description}</span>}
      <button type="submit">{loading?<Loader/>:"Add Treatment"}</button>
    </form>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AdminLayout>
   
  );
}

export default NewTreatment;

