import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios'
import { axiosPrivate } from "../../api/axios";
import {useSelector,useDispatch} from 'react-redux'
import { clearCalendar} from "../../redux/slices/calendar"
import Loader from "../../components/Loader/Loader";

const Success = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const reference = searchParams.get('reference');
    const dispatch=useDispatch()
    const auth = useSelector((state) => state.auth);
    const token = auth.accessToken
    const [prevTransaction, setPrevTransaction] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [bookingCreated, setBookingCreated] = useState(false); // Add this state variable

    useEffect(() => {
       
        const retrievePaymentDetails = async () => {
            try {
                const response = await axios.get(`https://api.paystack.co/transaction/verify/${reference}`, {
                    headers: {
                        Authorization: `Bearer sk_live_ed2386ea6e603cf4fc08a51bc5798b4ec1e8bdf8`,
                    }
                })
                setPrevTransaction(transaction);
                setTransaction(response.data);
            } catch (err) {
                console.log(err)
                setError(err);
                setLoading(false);
            }
        }
        retrievePaymentDetails()
    }, [reference]);

    useEffect(() => {
        if (transaction && prevTransaction !== transaction && !bookingCreated) {
            handleBooking(transaction.data);
        }
    }, [transaction, prevTransaction]);

    const handleBooking = async (data) => {
        dispatch(clearCalendar())
    try {
        // Check if there is already a booking with the same date and start time
        const response = await axiosPrivate.get(`/api/bookings/check?user=${data?.metadata?.user}&date=${data?.metadata?.date}&startTime=${data?.metadata?.startTime}&endTime=${data?.metadata?.endTime}`, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.success === true) {
           
            setLoading(false);
            return;
        }// Create the booking
        const createResponse = await axiosPrivate.post(`/api/bookings/`, {
            service: data?.metadata?.service,
            user: data?.metadata?.user,
            date: data.metadata?.date,
            startTime:data?.metadata?.startTime,
            endTime:data?.metadata?.endTime,
            amount:data?.metadata?.amount
        }, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            }
        });
        
        setLoading(false);
        setBookingCreated(true); // Set bookingCreated to true after creating the booking
    } catch (err) {
        if (err.response) {
            console.log('Booking not found, creating new booking...');
            try {
                // Create the booking
                const createResponse = await axiosPrivate.post(`/api/bookings/`, {
                    service: data?.metadata?.service,
                    user: data?.metadata?.user,
                    date: data.metadata?.date,
                    startTime:  data?.metadata?.startTime, 
                    endTime:data?.metadata?.endTime,
                    amount:data?.metadata?.amount
                }, {
                    headers: {
                        'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
                    }
                });
               
                setLoading(false);
                setBookingCreated(true); // Set bookingCreated to true after creating the booking
            } catch (err) {
               
                setError(err);
                setLoading(false);
            }
        } else {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
};





    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (loading) {
        return <div className="loading-container"><Loader/></div>
    }

    if (!transaction || !transaction.data.metadata) {
        return <div>No booking created...</div>;
    }

    return (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>

            <p> Booking created successfully! Visit your booking history<Link to='/bookings'>{" "}here</Link>.</p>
        </div>
    )

}

export default Success