import { axiosPrivate } from "../../../../api/axios";
import {  useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import AdminLayout from "../../components/Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader"
export const Booking = () => {
  const [booking, setBooking] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;

  const navigate=useNavigate()
  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`/api/bookings/booking/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setBooking(response.data.booking);
      } catch (err) {
        console.log(err);
      }
    };

    fetchBookingDetails();
  }, [id]);



  const confirmBooking = async () => {
    const confirmBooking = window.confirm("Are you sure you want to confirm this booking?");
    if (!confirmBooking) {
      return;
    }
    try {
      const response = await axiosPrivate.put(`/api/bookings/confirm/${id}`,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      setLoading(false);
      toast.success("You have confirmed the booking successfully!");
     navigate(-1)
    } catch (err) {
      setError(err)
      setLoading(false);
    }
  };
  return (
    <AdminLayout>
      <Box sx={{ height: "94vh", width: "90%" ,marginLeft:"30px"}}>
        {booking ? (
          <>
            <h2>Treatments</h2>
            <p>
              {booking.service.map((item) => (
                <div style={{display:"flex",gap:"20px"}}>
                <p><strong>Name</strong>: {item.name}</p>
                <p><strong>Duration</strong>: {item.duration} mins</p>
                </div>
              ))}
            </p>
            <h2>Date</h2>
            <>
            <p>{moment(booking.timeslot.date).format('dddd, MMMM D, YYYY')}</p>
            <p>{booking.timeslot.startTime}-{booking.timeslot.endTime}</p>
            
            </>
        
           <h2>Client</h2>
           <>
           <p>{booking.user.name + " "+ booking.user.surname } </p>
           <p></p>
           </>
           <h2>Total Amount</h2>
           <>
           <p>R{booking.amount}</p>
           
           </>
{
booking.status === "pending" &&(
  <button onClick={confirmBooking}>{!loading? <Loader/>:"Confirm Booking"}</button>
)
}
          
         </>
        ) : (
         <Loader/>
        )}
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AdminLayout>
  );
};
