import React from 'react'
import {FadeLoader} from "react-spinners";

const Loader = () => {
  return (
    <>
<FadeLoader color="black" />
    </>
  )
}

export default Loader