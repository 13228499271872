import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import authReducer from "../redux/slices/auth";
import cartReducer from "../redux/slices/cart";
import calendarReducer from "../redux/slices/calendar"

import { combineReducers } from "@reduxjs/toolkit";


const persistConfig = {
  key: "root",
  storage,
  blacklist: ['auth'],
  
};

const rootReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    calendar:calendarReducer,
  });

const persistedReducer = persistReducer(persistConfig,rootReducer);

const store = configureStore(
  {
    reducer: persistedReducer,
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);


export default store;
