import React from "react";
import "./Welcome.css";
import logo from "../../images/logo.png";
import { useNavigate ,Link} from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-container">
      <img className="logo" src={logo} />
      <p>
        Welcome to our Pumula Day Spa Booking App! Before you proceed with making a
        booking, we kindly request that you complete a consent form. This form
        outlines important information about our services, policies, and
        procedures, and ensures that you understand and agree to the terms
        before proceeding with your booking. By completing this form, you can
        also provide us with any relevant medical or personal information that
        will help us better serve you during your appointment. To complete the
        consent form, please click on the "Consent Form" link provided on this
        page. Once you have filled out and submitted the form, you can proceed
        with making your booking. Thank you for choosing our services, and we
        look forward to providing you with the best possible experience.
      </p>
      <Link style={{width:"100%"}} to='/consentform'>
      <button >Consent Form</button>
      </Link>
      <button onClick={() => navigate("/")}>Skip</button>
    </div>
  );
};

export default Welcome;
