import { useNavigate } from "react-router-dom"
import "./Unauthorized.css"
const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate("/login");

    return (
        <section className="unauthorized-container">
            <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
            <div >
                <button onClick={goBack}>Go back</button>
            </div>
        </section>
    )
}

export default Unauthorized