import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import "./ViewConsent.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { axiosPrivate } from "../../../../api/axios";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode"
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { toast,ToastContainer } from 'react-toastify';

const ViewConsent = () => {

    const { consentid } = useParams();

  
  const [loading, setLoading] = useState(false);


  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;


  const [formData, setFormData] = useState({
    skincareproduct: "",
    lookingfordifferentproduct: "",
    illnesses: "",
    injuries: "",
    medication: "",
    skinorders: "",
    pregnant: "",
    allergies: "",
    bloodpressure:"",
    selectedconditions:{
        heartconditions: false,
        diabetes: false,
        chronicheadaches: false,
        epilepsy: false,
        cancer: false,
        asthma: false,
        lactating: false,
        roaccutane: false,
        botox: false,
        none: false,
    },
   
    newsletter: "",
  });

  useEffect(() => {
    const getFormData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/consents/${consentid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data)
        setFormData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getFormData();
  }, []);

 
 

  return (
    <div>
      <Box
        sx={{
          height: "auto",
          width: "80%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Consent Form Details</h1>
        <form className="consent-form" >
          <label htmlFor="skincareproduct">
            Current skin care product used
          </label>
          <input
            type="text"
            id="skincareproduct"
            autoComplete="off"
            name="skincareproduct"
           value={formData.skincareproduct}
          />
        
          <label htmlFor="lookingfordifferentproduct">
            Are you looking for a different product
          </label>
          <RadioGroup
            name="lookingfordifferentproduct"
            value={formData.lookingfordifferentproduct}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          

          <label htmlFor="illnesses">General illnesses</label>
          <input
            type="text"
            id="illnesses"
            autoComplete="off"
            value={formData.illnesses}
            name="illnesses"
           
          />
 

          <label htmlFor="injuries">
            Any recent operations or injuries in the last 6 months
          </label>
          <input
            type="text"
            id="injuries"
            autoComplete="off"
            value={formData.injuries}
            name="injuries"
           
          />
        
          <label htmlFor="medication">Current medication used</label>
          <input
            type="text"
            id="medication"
            autoComplete="off"
            value={formData.medication}
            name="medication"
         
          />
        

          <label htmlFor="skinorders">Skin disorders</label>
          <input
            type="text"
            id="skinorders"
            autoComplete="off"
            value={formData.skinorders}
            name="skinorders"
           
          />
        

          <label htmlFor="bloodpressure">Blood pressure</label>
          <RadioGroup
            name="bloodpressure"
            value={formData.bloodpressure}
          >
            <FormControlLabel value="high" control={<Radio />} label="High" />
            <FormControlLabel
              value="normal"
              control={<Radio />}
              label="Normal"
            />
            <FormControlLabel value="low" control={<Radio />} label="Low" />
          </RadioGroup>
         
          <label htmlFor="pregnant">Pregnant & how far?</label>
          <input
            type="text"
            id="pregnant"
            autoComplete="off"
            value={formData.pregnant}
            name="pregnant"
            
          />
         

          <label htmlFor="allergies">Allergies</label>
          <input
            type="text"
            id="allergies"
            autoComplete="off"
            value={formData.allergies}
            name="allergies"
           
          />
         
          <label>Please select if you have any of the following</label>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
            checked={formData.selectedconditions.heartconditions}
                  name="heartconditions"
                />
              }
              label="Heart conditions"
            />
            <FormControlLabel
              control={
                <Checkbox
                checked={formData.selectedconditions.diabetes}
                  name="diabetes"
                />
              }
              label="Diabetes"
            />
            <FormControlLabel
              control={
                <Checkbox
                checked={formData.selectedconditions.chronicheadaches}
                  name="chronicheadaches"
                />
              }
              label="Chronic headaches"
            />
            <FormControlLabel
              control={
                <Checkbox
                checked={formData.selectedconditions.epilepsy}
                  name="epilepsy"
                />
              }
              label="Epilepsy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.cancer}
                  
                  name="cancer"
                />
              }
              label="Cancer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.asthma}
                 
                  name="asthma"
                />
              }
              label="Asthma"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.lactating}
                 
                  name="lactating"
                />
              }
              label="Lactating"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.roaccutane}
                 
                  name="roaccutane"
                />
              }
              label="Roaccutane (or generic) user"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.botox}
                  name="botox"
                />
              }
              label="Botox or fillers in the last 30 days"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.none}
                  name="none"
                />
              }
              label="None of the above"
            />
          </FormGroup>
        
          <label htmlFor="newsletter">
            Would you like to receive our monthly newsletter on email?
          </label>
          <RadioGroup
            name="newsletter"
            value={formData.newsletter}
           
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        
        
        </form>
       
      </Box>
    </div>
  );
};

export default ViewConsent;
