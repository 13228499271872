import React, { useState, useEffect } from "react";
import moment from "moment";
import "./Calendar.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Layout from "../Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { addDate,addStartTime,addDuration,addEndTime } from "../../redux/slices/calendar"; 

function Calendar() {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [timeSlots, setTimeSlots] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch=useDispatch()

  /**
   * 
   * This code will first retrieve the items array from the Redux store using the useSelector hook. 
   * Then, it initializes a totalDuration variable to 0 and iterates over the items array using a for loop. 
   * For each item, it adds the duration to the totalDuration variable. 
   * Finally, it returns the total duration as the result of the useSelector hook.
   * You can then use the duration variable in your useEffect hook to calculate the end time for each time slot.
   */
  const duration = useSelector((state) => {
    const items = state.cart.items;
    let totalDuration = 0;
    for (let i = 0; i < items.length; i++) {
      totalDuration += items[i].duration;
    }
    return totalDuration;
  });

  

  const { id } = useParams();
  const navigate = useNavigate();

  function handleDateChange(date) {
    const currentDate = moment();
  if (date.isBefore(currentDate, 'day')) {
    setSelectedDate(currentDate);
  } else {
    setSelectedDate(date);
  }
  }

  /**The  useEffect hook fetches the available time slots for the selected date by making a
   *  GET request to an API endpoint using Axios. It uses a cancel token to cancel the request if the
   *  component is unmounted before the response is received. If the response is successful, it transforms the
   *  time slot data into an array of objects containing startTime and endTime properties, based on the duration
   * of the selected treatment. The resulting array is set as the new timeSlots
   * state. If there is an error, it sets the error state with a message. */

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    setError(null);

    const source = axios.CancelToken.source();

    axios
      .get(
        `https://booking-app-backend.onrender.com/api/times?date=${selectedDate.format(
          "YYYY-MM-DD"
        )}`,
        {
          withCredentials: true,
          cancelToken: source.token,
        }
      )
      .then((response) => {
        if (isMounted) {
          const timeSlots = response.data.slots.map((timeSlot) => ({
            startTime: moment(timeSlot, "h:mm A"),
            endTime: moment(timeSlot, "h:mm A").add(duration, "minutes"),
          }));
          setTimeSlots(timeSlots);
         
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error(error);
          setError("Failed to fetch available time slots");
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
      source.cancel("Request canceled");
    };
  }, [selectedDate, duration]);

  const handleClick = (startTime, endTime) => {
    dispatch(addDate(selectedDate.format("YYYY-MM-DD")));
    dispatch(addStartTime(startTime));
    dispatch(addEndTime(endTime))
    dispatch(addDuration(duration))
    navigate('/confirm')
  };
  
  return (
    <Layout>
      <div className="calendar">
        <h2>Select a date:</h2>
        <input
          type="date"
          value={selectedDate.format("YYYY-MM-DD")}
          onChange={(e) => handleDateChange(moment(e.target.value))}
        />
        <h2>
          Next Available Time {selectedDate.format("dddd, MMMM D, YYYY")}:
        </h2>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <>
            {timeSlots && timeSlots.length > 0 ? (
              <ul className="timeslots-list">
                {
                  <li
                    onClick={() =>
                      handleClick(
                        moment(timeSlots[0].startTime).format("h:mm A"),
                        moment(timeSlots[0].endTime).format("h:mm A")
                      )
                    }
                    className="timeslots"
                  >
                    {moment(timeSlots[0].startTime).format("h:mm A")} -{" "}
                    {moment(timeSlots[0].endTime).format("h:mm A")}
                  </li>
                }
              </ul>
            ) : (
              <p>No time slots available for this date</p>
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default Calendar;
