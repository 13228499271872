import "./BottomNavigation.css";
import { FaUserAlt, FaHome, FaCalendarAlt } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import Badge from '@mui/material/Badge';
import { ShoppingBag } from "@mui/icons-material";
import {  Link} from "react-router-dom"

function BottomNavigation() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const quantity=useSelector((state)=>state.cart.totalQuantity)
  const decoded = jwt_decode(accessToken);
  const id = decoded.UserInfo.userId;

  return (
    <nav className="bottom-nav">
      <ul>
        <li>
          <Link to="/">
            <FaHome />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to={`/cart`}>
            
          <Badge badgeContent={quantity} color="primary">
      <ShoppingBag color="action" />
    </Badge>
           
            <span>Cart</span>
          </Link>
        </li>
        <li>
          <Link to="/bookings">
            <FaCalendarAlt />
            <span>Bookings</span>
          </Link>
        </li>
        <li>
          <Link to={`/profile/${id}`}>
            <FaUserAlt />
            <span>Profile</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default BottomNavigation;
