import "./sidebar.css";
import {
  Event,
  Home,
  LineStyle,
  Group,
  CalendarViewMonth,
  Spa,
  Settings,
  Logout,

} from "@mui/icons-material";
import { Link ,useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import useLogout from "../../../../hooks/useLogout";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const logout = useLogout()


  const handleLogout = async () => {
    const confirm = window.confirm("Are you sure you want to log out");
    if (confirm) {
      await logout();
    }
   
  }

  const auth = useSelector((state) => state.auth);
  const decoded = jwt_decode(auth.accessToken);
  const id = decoded.UserInfo.userId


  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/admin/dashboard" className="link">
              <li className="sidebarListItem active">
                <Home className="sidebarIcon" />
                Home
              </li>
            </Link>
            <Link to="/admin/dashboard/bookings" className="link">
              <li className="sidebarListItem active">
                <Event className="sidebarIcon" />
                Bookings
              </li>
            </Link>
            
            <Link to="/admin/dashboard/treatments" className="link">
              <li className="sidebarListItem">
                <Spa className="sidebarIcon" />
                Treatments
              </li>
            </Link>

            <Link to="/admin/dashboard/users" className="link">
              <li className="sidebarListItem active">
                <Group className="sidebarIcon" />
                Users
              </li>
            </Link>
            


            <li className="sidebarListItem active" onClick={handleLogout}>
              <Logout className="sidebarIcon" />
              Log Out
            </li>

          </ul>
        </div>

      </div>
    </div>
  );
}
