import { useState, useEffect} from "react";
import { useParams,useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import "./TreatmentDetailsPage.css";
import { axios } from "../../api/axios";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Person2Icon from "@mui/icons-material/Person2";
import { useDispatch } from "react-redux";
import { addItemToCart} from "../../redux/slices/cart";
import Loader from "../../components/Loader/Loader";

function TreatmentDetailsPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch=useDispatch()
const navigate=useNavigate()
  const { id } = useParams();

  useEffect(() => {
    const fetchTreatment = async () => {
      try {
        const response = await axios.get(`/api/treatments/treatment/${id}`, {
          withCredentials: true,
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
      }
    };
    fetchTreatment();

    //cleanup function

    return () => {
      setData(null);
      setLoading(true);
      setError(null);
    };
  }, [id]);

  const addToCart=()=>{
    dispatch(addItemToCart(data))
    navigate('/cart')
  }

 
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (loading) {
    return <div className="loading-container"><Loader/></div>;
  }
  return (
    <Layout>
     {
      data  ?(
        <div className="treatment-details-container">
        <div>
          <div className="treatment-details-image">
            <img src={data.image} alt={data.name} />
          </div>
          <div className="treatment-details-therapist">
            <h2>{data.name}</h2>
            <h2>R{data.price} </h2>
          </div>
          <hr />
          <div className="treatment-details-therapist">
            <h2 className="therapist-name">
              <Person2Icon />
              {data.therapist.name} {data.therapist.surname}
            </h2>
            <h2 className="duration">
              <AccessTimeIcon /> {data.duration} mins{" "}
            </h2>
          </div>
          <hr />
          <div className="treatment-details-description">
            <h2>About this treatment</h2>
            <p>{data.description}</p>
          </div>
          <hr />
          <div className="treatment-details-cta">
            
              <button onClick={addToCart}>Add to cart</button>
             
           
          </div>
        </div>
      </div>
      ):(
        <div>Treatment not found</div>
      )
     }
    </Layout>
  );
}

export default TreatmentDetailsPage;
