import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  accessToken: "",
  isAuthenticated: false,


};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addEmail: (state, action) => {
      state.email = action.payload;
    },
    addAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    addIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    resetAuth: (state) => initialState,
  },
});

export const { addEmail, addIsAuthenticated, addAccessToken, resetAuth,addPersist } =
  authSlice.actions;

export default authSlice.reducer;
