import "./treatment.css";
import React, { useState,useRef,useEffect } from 'react';
import AdminLayout from "../../components/Layout/Layout";
import {axiosPrivate} from '../../../../api/axios'
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { toast,ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Loader from "../../../../components/Loader/Loader";


const  Treatment=()=> {

  const [treatment, setTreatment] = useState({
    name: '',
    duration: '',
    image: null,
    price: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('');
  const errRef = useRef();
  

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken
  const decoded = jwtDecode(auth.accessToken);
 

  const{id}=useParams()


  // function to validate form fields
  const validateFields = () => {
    const validationErrors = {};
    if (!treatment.name.trim()) {
      validationErrors.name = 'Please enter a treatment name';
    }
    if (isNaN(parseInt(treatment.duration))) {
      validationErrors.duration = 'Please enter a valid duration (in minutes)';
    }
    if (isNaN(parseFloat(treatment.price))) {
      validationErrors.price = 'Please enter a valid price (in Rands)';
    }
    if (!treatment.image) {
      validationErrors.image = 'Please select an image';
    } 

    if (!treatment.description.trim()) {
      validationErrors.description = 'Please enter a treatment description';
    }
    return validationErrors;
  };

  
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'image') {
      const file = files[0];
        setTreatment({ ...treatment, image: file });
    } else {
      setTreatment({ ...treatment, [name]: value });
    }
    setErrors({});
  };
  
 
   // function to handle form submission
   const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateFields();
  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
  } else {
    setLoading(true);

    const formData = new FormData();
    formData.append('name', treatment.name);
    formData.append('duration', treatment.duration);
    formData.append('price', treatment.price);
    formData.append('description', treatment.description);
    formData.append('image', treatment.image);
   
    axiosPrivate.put(`/api/treatments/treatment/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
       
        setLoading(false);
        toast.success('Treatment updated!');
      })
      .catch((error) => {
        setErrMsg("Failed to create treatment");
        setLoading(false);
       
      });
    }
  };



//fetcch treatment details
useEffect(() => {
    const fetchTreatmentData = async () => {
      try {
        const response = await axiosPrivate.get(`/api/treatments/treatment/${id}`,
          {
            headers: {
              'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            }
          }
        )
      
        setTreatment(response.data)
      } catch (err) {
       
      }
    };
    fetchTreatmentData();
  }, []);


  return (
    <AdminLayout>
      <Box sx={{ height: 500, width: '90%' }}>
      <form className="editTreatmentForm" onSubmit={handleSubmit}>
<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
       
      <label htmlFor="treatmentName">Treatment Name:</label>
      <input
        type="text"
        id="treatmentName"
        name='name'
        value={treatment.name}
        onChange={handleChange}

      />
 {errors.name && <span className="error">{errors.name}</span>}
      <label htmlFor="duration">Duration:</label>
      <input
        type="number"
        id="duration"
        name='duration'
        value={treatment.duration}
        min="0"
        onChange={handleChange}
      />
{errors.duration && <span className="error">{errors.duration}</span>}

<label htmlFor="image" className="custom-file-upload">
  Upload Image
</label>
<input
  type="file"
  id="image"
  accept="image/*"
  name='image'
  onChange={handleChange}
/>
{errors.image && <span className="error">{errors.image}</span>}
{treatment.image && (
  <img
    alt="Selected Image"
    src={treatment.image instanceof File ? URL.createObjectURL(treatment.image) : treatment.image}
    className="preview-image"
  />
)}
      <label htmlFor="price">Price:</label>
      <input
        type="number"
        id="price"
        value={treatment.price}
        name='price'
        min="0"
        onChange={handleChange}
      />
{errors.price && <span className="error">{errors.price}</span>}
      <label htmlFor="description">Description:</label>
      <textarea
        id="description"
        value={treatment.description}
        name='description'
        onChange={handleChange}
        cols="50" 
        rows="10"
      />
     
{errors.description && <span className="error">{errors.description}</span>}
      <button type="submit">{loading?<Loader/>: "Save"}</button>
    </form>

      </Box>

    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AdminLayout>
   
  );
}

export default Treatment;

