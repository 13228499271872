import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import AdminLayout from "../../components/Layout/Layout";
import { axiosPrivate } from "../../../../api/axios";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";

export default function Home() {
  const [totalBookings, setTotalBookings] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const[latestbookings,setLatestBookings]=useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;
  const decoded = jwt_decode(token);
  const therapistId = decoded.UserInfo.userId;

  useEffect(() => {
    getTotalBookings();
    getTotalCustomers();
    getTotalRevenue();
  }, []);

  //Total Bookings
  const getTotalBookings = async () => {
    try {
      const response = await axiosPrivate.get(`/api/bookings/totalbookings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      console.log(response.data);
      setTotalBookings(response.data.totalBookings);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  //Total Bookings
  const getTotalCustomers = async () => {
    try {
      const response = await axiosPrivate.get(`/api/bookings/totalcustomers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setTotalCustomers(response.data.totalCustomers);
      console.log(response.data);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  //Total Bookings
  const getTotalRevenue = async () => {
    try {
      const response = await axiosPrivate.get(`/api/bookings/revenue`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setRevenue(response.data.totalRevenue);
      console.log(response.data);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };




  
  //Fetch bookings
  useEffect(() => {
    const fetchLatestBookings = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/bookings/therapist/latest?id=${therapistId}`,
          {
            headers: {
              'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            },
          }
        );
        
        setLatestBookings(response.data.bookings);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchLatestBookings();
  }, [therapistId]);

  return (
    <AdminLayout>
      <div className="home">
        <FeaturedInfo
          totalBookings={totalBookings}
          totalCustomers={totalCustomers}
          revenue={revenue}
        />
        <div className="homeWidgets">
          
          <WidgetLg latestbookings={latestbookings} />
        </div>
      </div>
    </AdminLayout>
  );
}
