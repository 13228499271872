import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload
      const existingItem = state.items.find(item => item._id === newItem._id)
    
      if (!existingItem) {
        state.items.push({
          ...newItem,
          quantity: 1
        })
        state.totalQuantity++
        state.totalPrice += newItem.price
      } else {
        existingItem.quantity++
      }
    },
    
    removeItemFromCart(state, action) {
      const _id = action.payload
      const existingItem = state.items.find(item => item._id === _id)
    
      if (!existingItem) {
        return
      }
    
      if (existingItem.quantity === 1) {
        state.items = state.items.filter(item => item._id !== _id)
      } else {
        existingItem.quantity--
      }
    
      state.totalQuantity--
      state.totalPrice -= existingItem.price
    },
    clearCart(state) {
      state.items = []
      state.totalQuantity = 0
      state.totalPrice = 0
    }
  }
})

export const { addItemToCart, removeItemFromCart, clearCart } = cartSlice.actions

export default cartSlice.reducer
