import { useEffect, useState } from "react";
import "./userList.css";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, Add } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AdminLayout from "../../components/Layout/Layout";
import { axios, axiosPrivate } from "../../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import moment from "moment";
import Dialog from "../../components/Dialog/Dialog";

export default function UsersList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;

  const decoded = jwt_decode(token);

  const id = decoded.UserInfo.userId;



  const handleClickOpen = (id) => {
 navigate(`/admin/dashboard/users/${id}/consents`)
  };

  //Fetch bookings
  useEffect(() => {
    setLoading(true);

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`/api/users`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchUsers();
  }, [token]);

  const columns = [
    {
      field: "name",
      headerName: " Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },

    {
      field: "roles",
      headerName: "Roles",
      width: 100,
    },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="userListButtons">
            <button onClick={()=>handleClickOpen(params.row._id)} className="userListEdit">
              Consent Form
            </button>
        
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </div>
        );
      },
    },
  ];

  //delete user
  const handleDelete = async (ID) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) {
      return;
    }
    setLoading(true);
    try {
      await axiosPrivate.delete(`/api/users/${ID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.filter((item) => item._id !== ID));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      toast.success("Failed to delete!");
    } finally {
      setLoading(false);
    }
  };

  const makeTherapist = async (userId) => {
    const confirm = window.confirm(
      "Are you sure you want to change the user to therapist"
    );
    if (!confirm) {
      return;
    }
    try {
      const response = await axiosPrivate.put(
        `/api/users/maketherapist/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success("You have updated the user to therapist successfully!");
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const removeTherapist = async (userId) => {
    const confirm = window.confirm(
      "Are you sure you want to remove the therapist"
    );
    if (!confirm) {
      return;
    }
    try {
      const response = await axiosPrivate.put(
        `/api/users/makeuser/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success("You have removed the therapist successfully!");
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getRowId = (row) => row._id || row.id;

  return (
    <AdminLayout>
      <div className="userList">
        <Box sx={{ height: 400, width: "90%" }}>
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    
    </AdminLayout>
  );
}
