import {axiosPrivate} from '../api/axios'
import  {useDispatch,useSelector} from  "react-redux"
import { resetAuth } from '../redux/slices/auth'

const useLogout = () => {
    const dispatch=useDispatch()
    const auth = useSelector((state) => state.auth);
    const token = auth.accessToken;
    const logout = async () => {
      
        try {
            const response = await axiosPrivate.post('api/auth/logout',{},{
                
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                }
            )
            dispatch(resetAuth())
        } catch (err) {
            console.log(err)
        }
    }

    return logout
}

export default useLogout