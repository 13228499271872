import { useEffect, useState } from "react";
import "./BookingList.css";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, Add, VolunteerActivismOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AdminLayout from "../../components/Layout/Layout";
import {  axiosPrivate } from "../../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useSelector } from "react-redux";

export default function TreatmentsList() {
const [data, setData] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);


  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;

  const decoded = jwt_decode(token);

  const therapistId = decoded.UserInfo.userId;


  const navigate=useNavigate()
  
  //Fetch bookings
  useEffect(() => {
   

    const fetchBookings = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/bookings/therapist?id=${therapistId}`,
          {
            headers: {
              'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
            },
          }
        );
        setData(response.data.bookings);
       
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchBookings();
  }, [therapistId]);

  
   //delete bookings
   const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Booking?"
    );
    if (!confirmDelete) {
      return;
    }
    setLoading(true);
    try {
      await axiosPrivate.delete(`/api/bookings/booking/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.filter((item) => item._id !== id));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      toast.success("Failed to delete!");
    } finally {
      setLoading(false);
    }
  };
  

  const columns = [
    {
      field: "customer",
      headerName: "Customer",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="bookingListItem">
            Ashlone Chibvuri
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="bookingListItem">R{params.row.amount}</div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="bookingListItem">
            {moment(params.row.timeslot.date).format("MMMM Do YYYY")}
          </div>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="bookingListItem">
            {params.row.timeslot.startTime}-{params.row.timeslot.endTime}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
    },

    
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <>
          <button className="bookingListEdit"  onClick={() => navigate(`/admin/dashboard/bookings/${params.row._id}`)}> View Booking</button>
          <DeleteOutline
              className="treatmentListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
              
        
          </>
        );
      },
    },
  ];

  
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  console.log(data)
  const getRowId = (row) => row._id || row.id;

  return (
    <AdminLayout>
      <div className="bookingList">
        <Box sx={{ height: "93vh" ,overflowX:"auto"}}>
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
          
            pageSizeOptions={[5]}
          />
        </Box>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AdminLayout>
  );
}
