import { useState, useEffect } from "react";
import "./ConfirmDetailsPage.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import jwt_decode from "jwt-decode";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../../redux/slices/cart";
import { clearCalendar } from "../../redux/slices/calendar";
import AddIcon from "@mui/icons-material/Add";

const ConfirmDetails = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const userId = jwt_decode(token);
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const date = useSelector((state) => state.calendar.date);
  const startTime = useSelector((state) => state.calendar.startTime);
  const endTime = useSelector((state) => state.calendar.endTime);
  const duration = useSelector((state) => state.calendar.duration);

  const handleCancel = () => {
    dispatch(clearCart());
    dispatch(clearCalendar());
    navigate("/");
  };

  const makePayment = async () => {
    // The email and amount are the only required parameters to initialize a transaction
    // on Paystack, but there several optional parameters you can add (see: https://paystack.com/docs/api/#transaction-initialize)

    const metadata = {
      service: cartItems,
      user: userId.UserInfo.userId,
      date: date,
      startTime: startTime,
      endTime: endTime,
      duration: duration,
      amount: totalPrice,
    };

    const body = JSON.stringify({
      amount: totalPrice * 100,
      email: userId.UserInfo.email,
      currency: "ZAR",
      metadata: metadata,
      callback_url: "https://booking-app-n0fy.onrender.com/payment/success",
    });

    try {
      let response = await axios.post(
        "https://api.paystack.co/transaction/initialize",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer sk_live_ed2386ea6e603cf4fc08a51bc5798b4ec1e8bdf8`,
          },
        }
      );

      // Paystack passes a status value in the JSON object that's true if the API call was successful, and false otherwise
      if (response.data && response.data.data.authorization_url) {
        window.location.href = response.data.data.authorization_url;
      }
      dispatch(clearCart());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/consents?id=${userId.UserInfo.userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
      
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    // Clean up function
    return () => {
      setData([]);
      setLoading(true);
      setError(null);
    };
  }, []);

  console.log(!data)
  return (
    <Layout>
      <div className="confirm-container">
        <h2> Confirm Booking Details</h2>

        <div className="confirm-booking-details">
          <hr className="confirm-line" />
          <h2 className="confirm-bookings-sub-headings">Treatment Details</h2>
          {cartItems.map((item) => (
            <div className="confirm-card">
              <img src={item.image} />
              <span>{item.name}</span>
              <span>R{item.price}</span>
            </div>
          ))}
          <hr className="confirm-line" />
          <h2 className="confirm-bookings-sub-headings">
            Date and Time Details
          </h2>
          <span>{moment(date).format("dddd, MMMM D, YYYY")}</span>
          <span>
            {startTime}-{endTime}
          </span>
          <hr className="confirm-line" />
          <h2 className="confirm-bookings-sub-headings">Consent Form</h2>
          {!data || data.length === 0 ? (
  <>
    <p>Fill the consent form before making your payment</p>
    <span onClick={() => navigate("/consentform")} className="add-consent-form">
      <AddIcon /> Add Consent Form
    </span>
  </>
) : null}

          {data &&
            data.length > 0 &&
            data.map((consent, index) => (
              <span
                onClick={() => navigate(`/consentform/${consent._id}`)}
                className="view-consent-details"
              >
                View Your Consent Details{" "}
              </span>
            ))}

          <hr className="confirm-line" />
          <h2 className="confirm-bookings-sub-headings">Total Price</h2>
          <span>R{totalPrice}</span>
          <div className="confirm-buttons">
            <button
              className="confirm-button"
              onClick={makePayment}
              disabled={!cartItems.length || totalPrice === 0 || !data.length}
            >
              Pay Now
            </button>
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConfirmDetails;
