import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import "./ConsentForm.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { axiosPrivate } from "../../api/axios";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode"
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { toast,ToastContainer } from 'react-toastify';

const EditConsentForm = () => {

    const { id } = useParams();

  const [formData, setFormData] = useState({
    skincareproduct: "",
    lookingfordifferentproduct: "",
    illnesses: "",
    injuries: "",
    medication: "",
    skinorders: "",
    pregnant: "",
    allergies: "",
    bloodpressure:"",
    selectedconditions:{
        heartconditions: false,
        diabetes: false,
        chronicheadaches: false,
        epilepsy: false,
        cancer: false,
        asthma: false,
        lactating: false,
        roaccutane: false,
        botox: false,
        none: false,
    },
   
    newsletter: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;
  const userId= jwt_decode(token)



  useEffect(() => {
    const getFormData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/consents/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getFormData();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      selectedconditions: { ...formData.selectedconditions, [name]: checked },
    });
  };

  const handleSubmission = async (event) => {
    event.preventDefault();
   const isValid= validateForm()
    
if (isValid) {
      setLoading(true);
      try {
        const response = await axiosPrivate.put(`/api/consents/${id}`, {...formData,user:userId.UserInfo.userId}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data)
        toast.success('You have updated your consent details!');
        setLoading(false);
      } catch (error) {
        setErrors(error.response.data);
        setLoading(false);
      }
      
    } 
   
  };

  const validateForm = () => {
    const errors = {};

    const checkboxNames = [
        "heartconditions",
        "diabetes",
        "chronicheadaches",
        "epilepsy",
        "cancer",
        "asthma",
        "lactating",
        "roaccutane",
        "botox",
        "none",
      ];

    if (formData.skincareproduct.trim() === "") {
      errors.skincareproduct = "This field is required";
    }
    if (formData.illnesses.trim() === "") {
      errors.illnesses = "This field is required";
    }
    if (formData.injuries.trim() === "") {
      errors.injuries = "This field is required";
    }
    if (formData.medication.trim() === "") {
      errors.medication = "This field is required";
    }
    if (formData.skinorders.trim() === "") {
      errors.skinorders = "This field is required";
    }
    if (formData.pregnant.trim() === "") {
      errors.pregnant = "This field is required";
    }
    if (formData.allergies.trim() === "") {
      errors.allergies = "This field is required";
    }

    if (formData.lookingfordifferentproduct.trim() === "") {
        errors.lookingfordifferentproduct = "Please select an option";
      }
      
      if (formData.newsletter.trim() === "") {
        errors.newsletter = "Please select an option";
      }

      if (formData.bloodpressure.trim() === "") {
        errors.bloodpressure = "Please select an option";
      }
      
      
      const checkedCheckboxes = checkboxNames.filter(
        (checkboxName) => formData.selectedconditions[checkboxName]
      );
      
      if (checkedCheckboxes.length === 0) {
        errors.checkboxes = "Please select at least one";
      }
            
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

 

  console.log(errors)

  return (
    <div>
      <Box
        sx={{
          height: "auto",
          width: "80%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Fill Out Consent Form Details</h1>
        <form className="consent-form" onSubmit={handleSubmission}>
          <label htmlFor="skincareproduct">
            Current skin care product used
          </label>
          <input
            type="text"
            id="skincareproduct"
            autoComplete="off"
            name="skincareproduct"
            value={formData.skincareproduct}
            onChange={handleChange}
          />
          {errors.skincareproduct && (
            <p className="error">{errors.skincareproduct}</p>
          )}

          <label htmlFor="lookingfordifferentproduct">
            Are you looking for a different product
          </label>
          <RadioGroup
            name="lookingfordifferentproduct"
            value={formData.lookingfordifferentproduct}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {errors.lookingfordifferentproduct && (
  <p className="error">{errors.lookingfordifferentproduct}</p>
)}

          <label htmlFor="illnesses">General illnesses</label>
          <input
            type="text"
            id="illnesses"
            autoComplete="off"
         
            name="illnesses"
            value={formData.illnesses}
            onChange={handleChange}
          />
          {errors.illnesses && <p className="error">{errors.illnesses}</p>}

          <label htmlFor="injuries">
            Any recent operations or injuries in the last 6 months
          </label>
          <input
            type="text"
            id="injuries"
            autoComplete="off"
        
            name="injuries"
            value={formData.injuries}
            onChange={handleChange}
          />
          {errors.injuries && <p className="error">{errors.injuries}</p>}

          <label htmlFor="medication">Current medication used</label>
          <input
            type="text"
            id="medication"
            autoComplete="off"
    
            name="medication"
            value={formData.medication}
            onChange={handleChange}
          />
          {errors.medication && <p className="error">{errors.medication}</p>}

          <label htmlFor="skinorders">Skin disorders</label>
          <input
            type="text"
            id="skinorders"
            autoComplete="off"
        
            name="skinorders"
            value={formData.skinorders}
            onChange={handleChange}
          />
          {errors.skinorders && <p className="error">{errors.skinorders}</p>}

          <label htmlFor="bloodpressure">Blood pressure</label>
          <RadioGroup
            name="bloodpressure"
            value={formData.bloodpressure}
            onChange={handleChange}
          >
            <FormControlLabel value="high" control={<Radio />} label="High" />
            <FormControlLabel
              value="normal"
              control={<Radio />}
              label="Normal"
            />
            <FormControlLabel value="low" control={<Radio />} label="Low" />
          </RadioGroup>
          {errors.bloodpressure && (
            <p className="error">{errors.bloodpressure}</p>
          )}
          <label htmlFor="pregnant">Pregnant & how far?</label>
          <input
            type="text"
            id="pregnant"
            autoComplete="off"
          
            name="pregnant"
            value={formData.pregnant}
            onChange={handleChange}
          />
          {errors.pregnant && <p className="error">{errors.pregnant}</p>}

          <label htmlFor="allergies">Allergies</label>
          <input
            type="text"
            id="allergies"
            autoComplete="off"
       
            name="allergies"
            value={formData.allergies}
            onChange={handleChange}
          />
          {errors.allergies && <p className="error">{errors.allergies}</p>}
          <label>Please select if you have any of the following</label>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.heartconditions}
                  onChange={handleCheckboxChange}
                  name="heartconditions"
                />
              }
              label="Heart conditions"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.diabetes}
                  onChange={handleCheckboxChange}
                  name="diabetes"
                />
              }
              label="Diabetes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.chronicheadaches}
                  onChange={handleCheckboxChange}
                  name="chronicheadaches"
                />
              }
              label="Chronic headaches"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.epilepsy}
                  onChange={handleCheckboxChange}
                  name="epilepsy"
                />
              }
              label="Epilepsy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.cancer}
                  onChange={handleCheckboxChange}
                  name="cancer"
                />
              }
              label="Cancer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.asthma}
                  onChange={handleCheckboxChange}
                  name="asthma"
                />
              }
              label="Asthma"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.lactating}
                  onChange={handleCheckboxChange}
                  name="lactating"
                />
              }
              label="Lactating"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.roaccutane}
                  onChange={handleCheckboxChange}
                  name="roaccutane"
                />
              }
              label="Roaccutane (or generic) user"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.botox}
                  onChange={handleCheckboxChange}
                  name="botox"
                />
              }
              label="Botox or fillers in the last 30 days"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.selectedconditions.none}
                  onChange={handleCheckboxChange}
                  name="none"
                />
              }
              label="None of the above"
            />
          </FormGroup>
          {errors.checkboxes && <p className="error">{errors.checkboxes}</p>}
          <label htmlFor="newsletter">
            Would you like to receive our monthly newsletter on email?
          </label>
          <RadioGroup
            name="newsletter"
            value={formData.newsletter}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {errors.newsletter && (
            <p className="error">{errors.newsletter}</p>
          )}
          <button className="consent-form-button">{loading ?"Loading...":"Save"}</button>
        </form>
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </Box>
    </div>
  );
};

export default EditConsentForm;
