import { useRef, useState, useEffect } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import { axios } from "../../api/axios";
import Loader from "../../components/Loader/Loader";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){1,2})(?=.*[!@#$%]).{8,24}$/;
const NAME_REGEX =/^[a-zA-Z]+$/;
const PHONE_NUMBER_REGEX = /^[0-9]{10}$/;

const Register = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const surnameRef = useRef();
  const phoneNumberRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);

  const [surname, setSurname] = useState("");
  const [validSurname, setValidSurname] = useState(false);
  const [surnameFocus, setSurnameFocus] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setValidName(NAME_REGEX.test(name));
  }, [name]);

  useEffect(() => {
    setValidSurname(NAME_REGEX.test(surname));
  }, [surname]);

  useEffect(() => {
    setValidPhoneNumber(PHONE_NUMBER_REGEX.test(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password, name, surname, phoneNumber, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PASSWORD_REGEX.test(password);
    const v3 = NAME_REGEX.test(name);
    const v4 = NAME_REGEX.test(surname);
    const v5 = PHONE_NUMBER_REGEX.test(phoneNumber);
    if (!v1 || !v2 || !v3 || !v4 || !v5) {
      setErrMsg("Invalid Entry");
      return;
    }
    setLoading(true);

    try {
      const response = await axios
.post('/api/auth/register',
        JSON.stringify({ password, email,name,surname,phoneNumber }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      setSuccess(true)
      //clear state and controlled inputs
      setEmail('');
      setPassword('');
      setMatchPassword('');
      setPhoneNumber('')
      setName('')
      setSurname('')
      setLoading(false)

    } catch (err) {
      console.log(err)
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Email exists');
      } else {
        setErrMsg('Registration Failed')
      }
      errRef.current.focus();
      setLoading(false)
    
    }
  }
  return (

    <div className="register-container">

      {

        success ? (<div>
          <h4>Click the link send to your email to activate your account!</h4>
          <p><a className="successLink" href='/login'>Sign In</a></p>
        </div>) : 
        
        (
          <div className="form-container">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            {loading ? <div>Loading...</div> :""}
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
            <div>
            <label htmlFor="email">
                 Name
                  <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validName || !name ? "hide" : "invalid"} />
                </label>
            <input
type="text"
id="name"
autoComplete="off"
onChange={(e) => setName(e.target.value)}
value={name}
required
aria-invalid={validName ? "false" : "true"}
aria-describedby="name-note"
ref={nameRef}
onFocus={() => setNameFocus(true)}
onBlur={() => setNameFocus(false)}
/>
<p id="name-note" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
<FontAwesomeIcon icon={faInfoCircle} />
Your surname must be at least one alphabetic character and no other characters.
</p>
            </div>

            <div>
            <label htmlFor="surname">
              Surname
              <FontAwesomeIcon icon={faCheck} className={validSurname ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validSurname || !surname ? "hide" : "invalid"} />
            </label>
            <input
              type="text"
              id="surname"
              autoComplete="off"
              onChange={(e) => setSurname(e.target.value)}
              value={surname}
              ref={surnameRef}
              required
              aria-invalid={validSurname ? "false" : "true"}
              aria-describedby="surname-note"
              onFocus={() => setSurnameFocus(true)}
              onBlur={() => setSurnameFocus(false)}
            />
            <p id="surname-note" className={surnameFocus && surname && !validSurname ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              Your surname must be at least one alphabetic character and no other characters.
            </p>
          </div>

          <div>
            <label htmlFor="phoneNumber">
              Phone Number
              <FontAwesomeIcon icon={faCheck} className={validPhoneNumber ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validPhoneNumber || !phoneNumber ? "hide" : "invalid"} />
            </label>
            <input
              type="text"
              id="phoneNumber"
              autoComplete="off"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              required
              ref={phoneNumberRef}
              aria-invalid={validPhoneNumber ? "false" : "true"}
              aria-describedby="phone-note"
              onFocus={() => setPhoneNumberFocus(true)}
              onBlur={() => setPhoneNumberFocus(false)}
            />
            <p id="phone-note" className={phoneNumberFocus && phoneNumber && !validPhoneNumber ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              Your phone number must be a valid phone number.
            </p>
          </div>

              <div>
                <label htmlFor="email">
                  Email
                  <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                </label>
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-invalid={validEmail ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />
                <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  An email address must contain at least one character before the @ symbol.<br />
                  An email address must contain a period (.) after the @ symbol. <br />
                  An email address must contain at least two characters after the period.<br />
                </p>
              </div>

              <div>
                <label htmlFor="password">
                  Password
                  <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} /></label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  ref={passwordRef}
                  aria-invalid={validPassword ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}

                />

                <p id="pwdnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  The password must be at 8 to 24 characters.<br />
                  The password must include uppercase and lowercase letters, a number and a special character.<br />
                  Allowed special characters:
                  <span className="specialCharacters" aria-label="exclamation mark">!</span>
                  <span className="specialCharacters" aria-label="at symbol">@</span>
                  <span className="specialCharacters" aria-label="hashtag">#</span>
                  <span className="specialCharacters" aria-label="dollar sign">$</span>
                  <span className="specialCharacters" aria-label="percent">%</span>
                </p>
              </div>

              <div>
                <label htmlFor="confirmpassword">Confirm Password
                  <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                  <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPassword ? "hide" : "invalid"} /></label>
                <input
                  type="password"
                  id="confirmpassword"
                  onChange={(e) => setMatchPassword(e.target.value)}
                  value={matchPassword}
                  required
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                />
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Passwords not matching
                </p>
              </div>
              <button disabled={!validEmail || !validPassword || !validMatch ? true : false}>{loading ? <Loader/>: 'Sign Up'}</button>
              <div className="links">
                <Link to='/login' style={{ textDecoration: 'none' }}>
                  <span>Log In</span>
                </Link>
              </div>

            </form>
          </div>
        )
      }

    </div>

  );
};

export default Register;
