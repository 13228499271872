import Axios from 'axios';


const BASE_URL = "https://booking-app-backend.onrender.com"
//const BASE_URL="http://localhost:1000"

export const axios = Axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = Axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});
