import { useEffect, useState } from "react";
import "./TreatmentsList.css";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, Add } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AdminLayout from "../../components/Layout/Layout";
import { axios, axiosPrivate } from "../../../../api/axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";

import "react-toastify/dist/ReactToastify.css";


export default function TreatmentsList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const token = auth.accessToken;

  //Fetch treatments
  useEffect(() => {
    setLoading(true);
    const fetchTreatments = async () => {
      try {
        const response = await axios.get("/api/treatments");
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchTreatments();
  }, []);

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="treatmentListItem">
            <img className="treatmentListImg" src={params.row.image} alt="" />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Treatment Name",
      width: 250,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <>
            <Link style={{textDecoration:"none"}} to={"/admin/dashboard/treatments/" + params.row._id}>
              <button className="treatmentListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="treatmentListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  //delete treatments
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this treatment?"
    );
    if (!confirmDelete) {
      return;
    }
    setLoading(true);
    try {
      await axiosPrivate.delete(`/api/treatments/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.filter((item) => item._id !== id));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      toast.success("Failed to delete!");
    } finally {
      setLoading(false);
    }
  };

  const getRowId = (row) => row._id || row.id;

  return (
    <AdminLayout>
      <div className="treatmentList">
        <div className="buttonContainer">
          <Link style={{textDecoration:"none"}} to="/admin/dashboard/newtreatment">
            <button className="addButton">
              <Add />
              Add
            </button>
          </Link>
        </div>
        <Box sx={{ height: 400, width: "90%" }}>
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AdminLayout>
  );
}
