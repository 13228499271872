import { axios } from '../api/axios';
import { addAccessToken } from '../redux/slices/auth';
import { useDispatch } from 'react-redux';

const useRefreshToken = () => {
    
      const dispatch=useDispatch()
      
    const refresh = async () => {
        const response = await axios.get('/api/auth/refresh', {
            withCredentials: true
        });
      dispatch(addAccessToken(response.data.accessToken))
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;