import { useRef, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './PasswordReset.css';
import { axios } from '../../api/axios'
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../../components/Loader/Loader';

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){1,2})(?=.*[!@#$%]).{8,24}$/

const PasswordReset = () => {

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    const userRef = useRef();
  const errRef = useRef();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');


    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        setErrMsg('');
    }, [password, matchPassword])


    const handleSubmit = async (e) => {
        e.preventDefault();

        const v1 = PASSWORD_REGEX.test(password);
        if (!v1) {
            setErrMsg("Invalid Entry");
            return;
        }
        setLoading(true)

        try {

            const response = await axios.post('/api/auth/forgotpassword',
                { token, password },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true)
            //clear state and controlled inputs
            setPassword('');
            setMatchPassword('');
            setLoading(false)

        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 404) {
                setErrMsg('Token not found');
            } else if (err.response?.status === 400) {
                setErrMsg('Link has expired, please request a new one')
            } else {
                setErrMsg('Failed to change password')
            }
            errRef.current.focus();
            setLoading(false)

        }
    }






    return (
        <div className='container'>

            {

                success ? (<div>
                    <h4>You have changed your password successfully!</h4>
                    <p><a className="successLink" href='/login'>Sign In</a></p>
                </div>) :

                    (
                        <div className="form-container">
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            
                            <h2>Reset Password</h2>
                            <form onSubmit={handleSubmit}>


                                <div>
                                    <label htmlFor="password">
                                        Password
                                        <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                                        <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} /></label>
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required
                                        aria-invalid={validPassword ? "false" : "true"}
                                        aria-describedby="pwdnote"
                                        onFocus={() => setPasswordFocus(true)}
                                        onBlur={() => setPasswordFocus(false)}

                                    />

                                    <p id="pwdnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        The password must be at 8 to 24 characters.<br />
                                        The password must include uppercase and lowercase letters, a number and a special character.<br />
                                        Allowed special characters:
                                        <span className="specialCharacters" aria-label="exclamation mark">!</span>
                                        <span className="specialCharacters" aria-label="at symbol">@</span>
                                        <span className="specialCharacters" aria-label="hashtag">#</span>
                                        <span className="specialCharacters" aria-label="dollar sign">$</span>
                                        <span className="specialCharacters" aria-label="percent">%</span>
                                    </p>
                                </div>

                                <div>
                                    <label htmlFor="confirmpassword">Confirm Password
                                        <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                                        <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPassword ? "hide" : "invalid"} /></label>
                                    <input
                                        type="password"
                                        id="confirmpassword"
                                        onChange={(e) => setMatchPassword(e.target.value)}
                                        value={matchPassword}
                                        required
                                        aria-invalid={validMatch ? "false" : "true"}
                                        aria-describedby="confirmnote"
                                        onFocus={() => setMatchFocus(true)}
                                        onBlur={() => setMatchFocus(false)}
                                    />
                                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        Passwords not matching
                                    </p>
                                </div>
                                <button className='passwordResetButton' disabled={!validPassword || !validMatch ? true : false}>{loading?<Loader/>:"Reset"}</button>
                            </form>
                        </div>
                    )
            }

        </div>
    );
};

export default PasswordReset;
