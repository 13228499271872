import { useRef, useState, useEffect } from 'react';
import './LogIn.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { axios } from '../../api/axios'
import jwt_decode from "jwt-decode";
import { useDispatch ,useSelector} from 'react-redux';
import { addEmail,addAccessToken,addIsAuthenticated } from '../../redux/slices/auth';
import Loader from '../../components/Loader/Loader';


const LogIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRedirect = location.state?.from?.pathname || "/welcome" ;


  const auth = useSelector((state) => state.auth);
  

  const dispatch=useDispatch()


  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const [loading, setLoading] = useState(false);

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post("/api/auth/login",
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;

      dispatch(addEmail(email))
      dispatch(addAccessToken(accessToken))
      dispatch(addIsAuthenticated(true))

      setEmail('');
      setPassword('');

     const decoded=jwt_decode(accessToken)
    
     if(decoded.UserInfo.roles=== "user"){
      navigate(userRedirect, { replace: true });
     }else if(decoded.UserInfo.roles=== "admin"){
      navigate("/admin/dashboard");
     }else{
      navigate('/unauthorized')
     }
    
      setLoading(false)

    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Email or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Invalid email or password');
      } else if(err.response?.status ===402){
        setErrMsg('Account not activated.Click the link send to your email');
      }
      else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
      setLoading(false)

    }
  }

  

  useEffect(() => {
    userRef.current.focus();
  }, [])
  



  return (
    <div className='login-container'>

<div className="form-container">
      <form onSubmit={handleSubmit}>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      <h2>Log In</h2>
        <div>
          <label htmlFor="email">Email</label>
          <input
            ref={userRef}
            type='text'
            id="email"
            autoComplete='off'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="password">Password</label>
          <input
            type='password'
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>


        <button >{loading?<Loader/>:"Sign In"}</button>
      
        <div className="links">
          <Link to='/forgotpassword' style={{ textDecoration: 'none', fontSize: "15px" }}>
            <span>Forgot Password</span>
          </Link>

          <Link to="/register" style={{ textDecoration: 'none', fontSize: "15px" }}>
            <span>Create New Account</span>
          </Link>
        </div>

      </form>

    </div>
   </div>
   
  );
};

export default LogIn;
