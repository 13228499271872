import React from "react";
import "./Cards.css";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Person2Icon from "@mui/icons-material/Person2";

const Cards = ({ data }) => {
  return (
    <div className="cards-container">
      {data &&
        data.map((item) => (
          <div key={item._id} className="card">
            <div className="card-image-container">
              <img src={item.image} alt="treatment" className="card-image" />
            </div>
            <div className="card-info">
              <h2 className="card-name">{item.name}</h2>
              <h2 className="card-price">R{item.price}</h2>
            </div>

            <Link
              style={{ textDecoration: "none" }}
              to={`/treatments/${item._id}`}
            >
              <button className="card-button">Book Now</button>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Cards;
