import {createSlice} from "@reduxjs/toolkit"

const initialState={
    date: null,
    startTime: null,
    endTime: null,
    duration:null
}

const calendarSlice=createSlice({
    name:"calendar",
    initialState,
    reducers:{
        addDate(state,action){
            state.date = action.payload
        },
        addStartTime(state,action){
            state.startTime = action.payload
        },
        addEndTime(state,action){
            state.endTime = action.payload
        },
        addDuration(state,action){
            state.duration = action.payload
        },
        clearCalendar(state) {
            state.date = null
            state.startTime = null
            state.endTime = null
            state.duration=null
          }
    }
})

export const {addDate,addDuration,addEndTime,addStartTime, clearCalendar}=calendarSlice.actions

export default calendarSlice.reducer